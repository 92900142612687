import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useKeenSlider } from "keen-slider/react"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"

import * as styles from "./Reviews.module.css"

const Reviews = () => {
  const { prismicCommon } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          reviews_pre_title {
            text
          }
          reviews_title {
            text
          }
          review_quotes {
            quote {
              html
            }
          }
        }
      }
    }
  `)

  const commonData = prismicCommon.data
  const quoteItems = commonData.review_quotes
  // Slider is 100% ready or not
  const [sliderReady, setSliderReady] = useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    duration: 1000,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },

    created() {
      // Slider is 100% ready to work
      setSliderReady(true)
    },
  })

  return (
    <>
      <Container className="!pb-0 mb-[-24px]">
        <Title preTitle>{commonData.reviews_pre_title.text}</Title>
        <Title>{commonData.reviews_title.text}</Title>
      </Container>
      <div className={styles.navigationWrapper}>
        <div
          ref={sliderRef}
          className={`keen-slider  ${sliderReady ? "" : "block"}`}
        >
          {quoteItems.map(({ quote }, index) => {
            return (
              <div className="keen-slider__slide" key={index}>
                <Container className="!pl-[75px] sm:!pl-[100px] lg:!pl-[150px]">
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: quote.html }} />
                  </div>
                </Container>
              </div>
            )
          })}
        </div>
        {slider && (
          <div className="text-heading">
            <ArrowLeft
              onClick={e => {
                e.stopPropagation() || slider.prev()
              }}
              // Stop disabled arrow because it is infinite looped slider
              // disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={e => {
                e.stopPropagation() || slider.next()
              }}
              // Stop disabled arrow because it is infinite looped slider
              // disabled={currentSlide === slider.details().size - 1}
            />
          </div>
        )}

        {slider && (
          <div
            className={`absolute z-40 bottom-2 md:bottom-4  left-1/2 transform -translate-x-1/2 ${styles.dots}`}
          >
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  className={`${styles.dot} ${
                    currentSlide === idx ? "!bg-primary" : "bg-heading"
                  }`}
                >
                  {""}
                </button>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

export default Reviews

function ArrowLeft(props) {
  const disabled = props.disabled ? styles.arrowDisabled : ""
  return (
    <svg
      onClick={props.onClick}
      className={`lg:!left-7 ${styles.arrow} ${styles.arrowLeft} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

function ArrowRight(props) {
  const disabled = props.disabled ? styles.arrowDisabled : ""
  return (
    <svg
      onClick={props.onClick}
      className={`lg:!right-7 ${styles.arrow} ${styles.arrowRight} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}
